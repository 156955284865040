<template>
  <v-dialog
    fullscreen
    transition="slide-y-reverse-transition"
    :value="dialog"
    content-class="rai-txt-dialog"
  >
    <v-app-bar v-if="!hideToolbar" fixed elevation="1" class="rai-txt-titlebar">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>$vuetify.icons.back</v-icon>
      </v-btn>
      <v-toolbar-title v-text="title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="button"></slot>
    </v-app-bar>
    <v-sheet class="rai-txt-sheet">
      <v-container fluid class="pa-0">
        <v-row no-gutters class="py-12">
          <v-col class="px-3">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-footer fixed class="rai-txt-footer-form">
      <slot name="form" />
    </v-footer>
  </v-dialog>
</template>

<script>
export default {
  name: "DetailViewMobile",
  props: {
    title: {
      type: String,
      default: null,
    },
    back: {
      type: String,
      default: "/texting",
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
};
</script>

<style lang="scss">
.rai-app-xs {
  .rai-txt-titlebar {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .rai-txt-footer-form {
    background-color: rgba(0, 0, 0, 0);
  }
  .rai-txt-dialog::after {
    position: fixed;
    bottom: 0;
    display: block;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    margin-top: -50px;
    height: 50px;
    width: 100%;
    z-index: 2;
    content: "";
  }
  .rai-txt-sheet {
    background-color: map-get($shades, "white");
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0px;
    min-height: 0px;
    position: relative;
    width: 100%;
  }

  .rai-message-thread-form {
    bottom: 0;
    position: fixed;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 12px;
  }
}
</style>
